import React, { Suspense } from "react"
import { Col, Row } from "react-bootstrap"

import ButtonWithTooltip from "@/_components/ButtonWithTooltip"
import FormInput from "@/_components/FormInput"
import PanelInner from "@/_components/PanelInner"
const MapMarkerModal = React.lazy(() => import("@/_components/MapMarkerModal.jsx"))

import PersonEditAddressesModal from "@/person/PersonEditAddressesModal"

import { getLabel, getList } from "@/_services/lists"
import { loc } from "@/_services/localization"
import { getOptions } from "@/_services/userConfiguration"

class AddressesPanel extends React.Component {
  state = {}

  componentDidMount() {
    getList("country", () => this.setState({ countryListLoaded: true }))
  }

  handleEditPersonAddressesModalClose = () => {
    this.setState({ showEditAddressesModal: false })
  }

  handleMapMarkerModalClose = () => {
    this.setState({ showMapAddressesModal: false })
  }

  handleSetAddressState = (patch, index) => {
    if (patch) {
      const { entity, handleSetEntityState } = this.props

      const copyAddresses = (entity?.addresses || []).map(address => ({ ...address }))
      if (!copyAddresses.length) copyAddresses.push({})
      copyAddresses[index] = { ...copyAddresses[index], ...patch }

      handleSetEntityState({ addresses: copyAddresses })
    }
  }

  formatAddressPreview = address => {
    const addressConfig = getOptions("address")
    if (!addressConfig?.preview) {
      return (
        <>
          {address.address1 && <div>{address.address1}</div>}
          {(address.zipcode || address.city) && (
            <div>
              {address.zipcode}
              {address.zipcode ? " " : ""}
              {address.city}
            </div>
          )}
        </>
      )
    }
    return addressConfig.preview.map((line, index) => (
      <div key={index}>
        {line.map((el, elIndex) => (
          <span key={`${index}-${elIndex}`} className={el.className}>
            {["streetType", "country"].includes(el.name) ? getLabel(el.name, address[el.name]) : address[el.name]}
            {el.delimiter || ""}
          </span>
        ))}
      </div>
    ))
  }

  render() {
    const { showMapAddressesModal, showEditAddressesModal } = this.state
    const {
      entity,
      readOnly,
      bsSize,
      bsStyle,
      modelPath,
      handleSetEntityState,
      show,
      extraInfo,
      language,
      region,
      showOpenModalButton = true,
    } = this.props
    const { addresses = [] } = entity
    const addressConfig = getOptions("address")

    const isAnyAdress = addresses[0]?.location

    let errorFormat
    // Find the first errorFormat in the addresses
    for (const address of addresses) {
      for (const prop of Object.keys(address || {})) {
        if (address[prop]?.errorFormat) {
          errorFormat = address[prop].errorFormat
          break
        }
      }
      if (errorFormat) break
    }

    return (
      <PanelInner
        title={loc`Addresses`}
        titleSideText={!addressConfig?.hideCollapsedPreview && <span className="font-weight-normal font-theme">{addresses?.[0]?.addressLine}</span>}
        collapse={false}
        hidePostTitleWhen="expanded"
        className={show ? "" : "d-none"}
        sectionProps={{ errorFormat }}
        buttons={
          <>
            {showOpenModalButton && (
              <ButtonWithTooltip
                bsStyle={bsStyle || "primary"}
                bsSize={bsSize || "xs"}
                fill
                className={`icn-${readOnly ? "eye" : "edit"} icn-xs`}
                btnClassName="pull-right flex-center"
                data-test="edit-person-address-btn"
                onClick={() => this.setState({ showEditAddressesModal: true })}
                tooltip="Edit"
              />
            )}
            {isAnyAdress && (
              <ButtonWithTooltip
                bsStyle={bsStyle || "primary"}
                bsSize={bsSize || "xs"}
                fill
                className="icn-map-marker icn-xs"
                btnClassName="pull-right flex-center"
                data-test="edit-person-address-btn"
                onClick={() => this.setState({ showMapAddressesModal: true })}
                tooltip="Show map"
              />
            )}
          </>
        }
      >
        <Row className="pdb-theme">
          {addresses
            .filter(it => it && (it.addressLine || it.address1 || it.city))
            .map((address, index) => (
              <Col xs={12} sm={6} md={4} lg={3} key={index} className="mb-theme">
                {!addressConfig?.hidePreviewType && address.type && (
                  <FormInput readOnly badge obj={address} field="type" select="addressType" fcClassName="mb-theme" />
                )}
                {this.formatAddressPreview(address)}
              </Col>
            ))}
        </Row>
        {showEditAddressesModal && (
          <PersonEditAddressesModal
            readOnly={readOnly}
            extraInfo={extraInfo}
            language={language}
            region={region}
            person={entity}
            modelPath={modelPath}
            onClose={this.handleEditPersonAddressesModalClose}
            handleSetEntityState={handleSetEntityState}
          />
        )}
        {showMapAddressesModal && (
          <Suspense fallback={true}>
            <MapMarkerModal
              autoPosition
              hideCoordinates
              disabled
              onClose={this.handleMapMarkerModalClose}
              coordinates={addresses[0].location.coordinates}
              markers={addresses.map(address => ({
                color: "green",
                description: address.addressLine,
                location: address.location && { coordinates: address.location.coordinates },
              }))}
            />
          </Suspense>
        )}
      </PanelInner>
    )
  }
}

export default AddressesPanel
