import { formatAddress, toQueryString } from "basikon-common-utils"
import React, { createRef } from "react"
import { Grid } from "react-bootstrap"

import Card from "@/_components/Card"
import CustomButton from "@/_components/CustomButton"
import LayoutCard from "@/_components/LayoutCard"
import Table, { mergeColumns } from "@/_components/Table"

import { getEntityFilterFunction } from "@/_services/entity"
import { getLabel, getList } from "@/_services/lists"
import { loc } from "@/_services/localization"
import { fetchPinnedEntities, getPageConfig } from "@/_services/userConfiguration"
import {
  applyPageAdvancedSearchConfig,
  applyPageConfigFields,
  getClientRouteKey,
  getEntities,
  getQueryParam,
  getQueryParams,
  hasQueryParamsChanged,
  mergeQueryParams,
  searchParamToObject,
  toQueryParams,
} from "@/_services/utils"

class ProspectsOrPersonsPage extends React.Component {
  constructor(props) {
    super(props)
    const {
      location: { pathname: clientRoute },
    } = props

    const { path } = props.match
    const isProspect = path.startsWith("/prospects")
    const isPerson = path.startsWith("/persons")
    const entityName = isProspect ? "Prospect" : "Person"
    const pageName = getClientRouteKey(clientRoute) || `${entityName}sPage`
    const pageConfig = getPageConfig(pageName)
    const defaultColumns = [
      { title: "Registration", name: "registration", linkTo: `/${entityName.toLowerCase()}/{registration}` },
      { title: "Organization", name: "organization.name", hidden: true },
      { title: "Status", name: "status", select: "personStatus", badge: true },
      { title: "Name", name: "name" },
      { title: "Role", name: "role" },
      { title: "Type", name: "type", select: "personType" },
      { title: "Address", name: "address" },
    ]

    this.state = {
      isPerson,
      isProspect,
      persons: [],
      loading: false,
      entityServerRoute: isProspect ? "/api/person/prospects" : "/api/person/persons",
      entityClientRoute: isProspect ? "/prospects" : "/persons",
      entityName,
      pageName,
      pageConfig,
      columns: mergeColumns(defaultColumns, pageConfig?.columns),
      filterFunction: getEntityFilterFunction(entityName),
      pinnedData: [],
    }

    this.preventAutoRefresh = createRef()
  }

  componentDidMount = async () => {
    const { pageName } = this.state
    this.getPersons()

    getList("personType", () => this.setState({ loaded1: true }))
    getList("personRole", () => this.setState({ loaded2: true }))
    getList("personStatus", () => this.setState({ loaded3: true }))
    applyPageAdvancedSearchConfig(pageName, personSearchFields)
  }

  componentDidUpdate(prevProps) {
    const [search, filter] = getQueryParams(this.props, ["search", "filter"])
    const prevSearch = getQueryParam(prevProps, "search")

    if (search !== prevSearch && search && filter?.trim()) this.getPersons(undefined, true)
    else if (!this.preventAutoRefresh.current && hasQueryParamsChanged(this.props, prevProps)) this.getPersons()
  }

  getPersons = async (params, mergeWithFilteredEntities) => {
    const { location, history } = this.props
    const { entityName, entityClientRoute, columns, pageConfig, persons: prevPersons = [] } = this.state
    let queryParams = { ...searchParamToObject(location.search), ...(params || {}) }
    delete queryParams.addButtonQueryParam
    delete queryParams.pageTitle
    if (columns.find(it => it.name === "organization.name" && !it.hidden)) queryParams.include = "organizationName"

    this.setState({ loading: true })

    const allQueryParams = { ...queryParams, ...(pageConfig?.query || {}) }
    let [persons, pinnedData] = await Promise.all([
      getEntities(entityName, allQueryParams),
      fetchPinnedEntities({ entityName, queryParams: allQueryParams }),
    ])

    // Merge the fetched entities with those already filtered within the browser, as our filter function does not match with the MongoDB search.
    // This ensures that an entity visible via the filter function is not missed in the MongoDB search results, preventing confusion for the user.
    if (mergeWithFilteredEntities) {
      const filterEntities = getEntityFilterFunction(entityName)
      const prevFilteredPersons = filterEntities(prevPersons, queryParams.filter)
      const prevFilteredPersonRegistrations = new Set(prevFilteredPersons.map(person => person.registration))

      const newPersonsExcludingFiltered = persons.filter(person => !prevFilteredPersonRegistrations.has(person.registration))
      persons = [...prevFilteredPersons, ...newPersonsExcludingFiltered]
    }

    persons = applyPageConfigFields(pageConfig, persons)
    pinnedData = applyPageConfigFields(pageConfig, pinnedData)

    this.setState({ persons, pinnedData: this.formatTableData(pinnedData), loading: false })

    if (params) {
      this.preventAutoRefresh.current = true
      queryParams.page = 1
      queryParams = mergeQueryParams(location.search, queryParams)
      history.replace(`${entityClientRoute}${queryParams}`)
      this.preventAutoRefresh.current = false
    }
  }

  formatTableData = data => {
    return data.map(person => ({
      ...person,
      address: formatAddress(person.addresses?.[0]),
      role: (person.roles || []).map(r => getLabel("personRole", r.role) || `${r.role} (???)`).join(", "),
    }))
  }

  render() {
    const { persons = [], loading, entityName, pageConfig, columns, pinnedData, filterFunction } = this.state
    const { location, history } = this.props
    const { addButtonQueryParam, pageTitle, ...queryParams } = searchParamToObject(location.search)
    const title = loc(pageTitle || pageConfig?.title || `${entityName}s`)
    const { filter = "" } = queryParams
    const filteredPersons = filter?.trim() ? filterFunction(persons, filter) : persons

    const data = this.formatTableData(filteredPersons)

    return (
      <Grid className="persons-page">
        {!pageConfig?.hideKpis && (
          <LayoutCard
            noCard
            rows={[{ type: "content", props: { getContentOnUrlChange: false, name: `${entityName.toLowerCase()}s-kpi`, noCard: false } }]}
          />
        )}

        <Card
          title={title}
          action={
            <CustomButton
              fill
              pullRight
              bsSize="small"
              bsStyle="primary"
              hidden={pageConfig?.addButton?.hidden}
              className="inline-flex-center"
              onClick={() => {
                let addButtonLink = pageConfig?.addButton?.linkTo || "/" + entityName.toLowerCase()
                if (addButtonQueryParam) addButtonLink += `?${decodeURIComponent(addButtonQueryParam)}`
                else if (pageConfig?.addButton?.includeUrlQueryParams) addButtonLink += toQueryString(toQueryParams(queryParams))
                history.push(addButtonLink)
              }}
            >
              <i className="icn-plus icn-xs mr-5px" />
              {loc(pageConfig?.addButton?.label || "Add")}
            </CustomButton>
          }
        >
          <Table
            useSearchRowComponent
            exportDataFileName={title}
            getEntities={this.getPersons}
            searchFields={personSearchFields}
            showRowsCount
            data={data}
            columns={columns}
            loading={loading}
            pinnedData={pinnedData}
            entityName={entityName}
            pageConfig={pageConfig}
          />
        </Card>
      </Grid>
    )
  }
}

export default ProspectsOrPersonsPage

export const personSearchFields = [
  [
    { field: "registration", colProps: { xs: 12, sm: 4 }, regex: true },
    { field: "name", colProps: { xs: 12, sm: 5, md: 6 }, regex: true },
    { field: "birthDate", colProps: { xs: 12, sm: 3, md: 2 }, type: "date" },
  ],
  [
    { field: "type", type: "multiple", select: "personType", colProps: { xs: 12, sm: 4 } },
    { field: "address", colProps: { xs: 12, sm: 8 } },
  ],
  [
    { field: "role", type: "multiple", select: "personRole", colProps: { xs: 12, sm: 4 } },
    { field: "status", type: "multiple", select: "personStatus", colProps: { xs: 12, sm: 4 } },
    { field: "nationality", type: "multiple", select: "country", colProps: { xs: 12, sm: 4 } },
  ],
  [
    { field: "legalForm", select: "legalForm", colProps: { xs: 12, sm: 4 } },
    { field: "activityCode", select: "activityCode", colProps: { xs: 12, sm: 4 } },
  ],
  [
    { field: "accountingReference", colProps: { xs: 12, sm: 4 } },
    { field: "internalReference", colProps: { xs: 12, sm: 4 } },
    { field: "externalReference", colProps: { xs: 12, sm: 4 } },
  ],
  [
    { field: "mobile", type: "phone", colProps: { xs: 12, sm: 4 } },
    { field: "phone", type: "phone", colProps: { xs: 12, sm: 4 } },
    { field: "email", colProps: { xs: 12, sm: 4 }, regex: "fromStart" },
  ],
]
